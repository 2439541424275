<template>
  <div class="home-container">
    <nav>蔚县便民服务站</nav>
	<template>
		<van-tabs sticky v-model="active" @click="onClick">
		  <van-tab v-for="(category,index) in this.categoryList" :title="category.name" :key="category.id" >
			  <div class="padding20X paddingX14 van-bgcolor-white">
				<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<!-- 下拉刷新 -->
				<!-- <van-pull-refresh
				  v-model="isLoading"
				  success-text="刷新成功"
				  @refresh="onRefresh"
				> -->
				  <van-cell style="text-align: left;"
				    v-for="(information, index)  in informationList"
				    :key="index"
				    :title="'【'+information.isPush + '】' + information.content"
					:is-link="!!information.articleUrl"
				  >
				  <!-- v-if="information.articleUrl"  https://blog.csdn.net/qq_62182135/article/details/138564018   -->
				  
					<a style="width: 40px;" v-if="information.articleUrl" :href="information.articleUrl">查看手机号</a>
				  </van-cell>
				  <!-- </van-pull-refresh> -->
				</van-list>
				
			  </div>
		  </van-tab>
		</van-tabs>
	 </template>
  </div>
</template>

<script>
import http from '../util/http.js';
import axios from "axios";

export default {
  name: 'HomePage',
  components: {},
  props: {},
  data() {
    return {
		active: 0,
		error: false,
		loading: false,
		finished: false,
		pageSize: 10,
		pageNum: 0,
		categoryId: 1,
		categoryName: '',
		categoryList: [],
		informationList: [],
	}
  },
  computed: {},
  watch: {},
  created() {
	  // 初始化生命周期
	  this.getBigCategoryList();
  },
  mounted() {
  },
  methods: {
	 //  // 下拉刷新
	 //  onRefresh() {
		// getpro({
		//   num: this.num,
		//   index: this.index,
		// }).then((res) => {
		//   this.prolist = res.msg;
		//   this.isLoading = false;
		//   this.index = 0; //页码也需要初始化
		//   this.finished = false //
		// })
	 //  },
	onLoad() {
		console.log("加载更多 >>>>>>>>>>>>>>>>>>>>>>>>> ")
		
		this.pageNum ++;
		// 开始异步请求数据
		let params = {
			  categoryId: this.categoryId,
		  	  pageNum: this.pageNum,
		  	  pageSize: this.pageSize
		}
		this.selectDesensitizationList(params);
	  },
	  
	  //防抖
	  delay(){
		  
	  },
	  // 点击tab
	  onClick(index, title) {
		  this.informationList = [];
		  this.pageNum = 0;
		  console.log("点击tab >>>>>>>>>>>>>>>>>>>>>>>>> ")
		  
		  this.categoryId = this.categoryList[index].id;
		  // 开始异步请求数据
		  let params = {
				categoryId: this.categoryId,
				pageNum: 1,
		    	pageSize: this.pageSize
		  }
		  this.selectDesensitizationList(params);
	  },
	  selectDesensitizationList(params){
	  	http.requestPost('/imbmfw/information/selectDesensitizationList',params).then(res => {
	  	      console.log("selectDesensitizationList >>>>>>>>>>>>>>>>>>>>>>>>> ")
	  	      console.log(res)
	  	      if(res.code == 200) {
	  	  		// 添加信息
	  	  	    // this.informationList = res.rows;
				this.informationList.push(...res.rows);
	  	  		console.log(this.informationList)
	  			this.loading = false
	  			this.finished = res.rows.length < this.pageSize
	  	      }
	  	  })
	  	  .catch((error) => {
	  	  	this.loading = false;
	  	  	console.log(error)
	  	});	  
	  },
	  // 查询大类
	  getBigCategoryList(){
	    http.requestGet('/imbmfw/category/bigList').then(res => {
	      console.log("getCategoryList >>>>>>>>>>>>>>>>>>>>>>>>> ")
	      console.log(res)
	      res.rows.forEach(arr => {
	        let option ={}
	        option.id = arr.categoryId
	        option.name = arr.categoryName
	        this.categoryList.push(option)
	      })
	      console.log("categoryList >>>>>>>>>>>>>>>>>>>>>>>>> ")
	      console.log(this.categoryList)
	  
	    }).catch(err => {
	      console.log(err)
	    });
	  },
  }
}
</script>

<style scoped></style>


